import React from 'react';
import styled from 'styled-components/macro';
import { FWSection, Intro } from '../styles/mainStyles';
import { Project } from '../components/Project';

export const DevSchoolWorkEN = ({ mainColor, secondaryColor }) => {
  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      <ContentWrapper>
        <h1>Bootcamp-projects</h1>
        <Intro mainColor={mainColor}>
          Selected projects from the frontend bootcamp I attended during fall
          2020/spring 2021. The projects are small with different tech subjects.
          During the bootcamp we created 21 projects in total.
        </Intro>

        <Project
          color={mainColor}
          imgSrc="../assets/labyrinth.jpg"
          name="API-/Redux-labyrinth"
          tech="Styled Components, JavaScript/ES6, React, Redux, Local Storage"
          url="https://seaton.netlify.app"
          task="A game where the player enters their name and gets to follow a path made by continuously sending POST-requests to an API, recieving new directions as response."
        />
        <Project
          color={mainColor}
          name="Todo-app"
          task="Classic todo-app that allows the user to add tasks, check them, delete them and sort them by category. Tasks are stored locally."
          imgSrc="../assets/todo.jpg"
          tech="Styled Components, JavaScript/ES6, React, Redux, Local Storage"
          url="https://todos-by-karin.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Survey"
          task="A responsive survey website featuring questions with input fields such as textboxes, checkboxes, radiobuttons and a dropdown selector. Survey results are connected to a Realtime Database on Google Firebase."
          imgSrc="../assets/survey.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, React, Firebase Realtime Database"
          url="http://iwannabecomeafrontenddev.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Movies"
          task="A page listing the latest movies from The Movie Database API, sorted by different categories. Each movie also has a specific page."
          imgSrc="../assets/movies.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, React, React Router"
          url="https://movies-by-karin.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Happy Thoughts"
          task="Small site where you can send happy thoughts to a database, list all thoughts and like them. Site also features a like-count per session and error handling."
          imgSrc="../assets/happy.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, React"
          url="http://happihappiness.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Weather App"
          task="Classic weather app built entirely in vanilla JS/HTML/CSS. Features geolocation, different types of weather data and each one is portrayed with a gif."
          imgSrc="../assets/weather.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, API, Geolocation"
          url="https://weatherbug.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Smoothiemixer"
          task="A smoothie ingredient generator where recepes are created randomly. The user can also pick a favorite fruit, which will then appear more often in the recipes."
          imgSrc="../assets/smoothie.jpg"
          tech="HTML5, CSS3, JavaScript/ES6"
          url="https://smoothday.netlify.app/"
        />
      </ContentWrapper>
    </FWSection>
  );
};

const ContentWrapper = styled.div`
  width: 76%;

  h1 {
    color: var(--color-brightRed);
    font-size: 56px;
  }

  @media (max-width: 700px) {
    width: 100%;

    h1 {
      font-size: 42px;
    }
  }
`;
