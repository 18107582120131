import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

export const Project = ({
  color,
  name,
  client,
  task,
  imgSrc,
  tech,
  url,
  text,
}) => {
  const location = useLocation();

  return (
    <CaseWrapper>
      <div className="case-text-wrapper">
        <h3>
          <span style={{ background: color, color: '#fff' }}>
            {location.pathname.includes('/en') ? 'Project:' : 'Projekt:'}
          </span>{' '}
          <span className="case-title">{name}</span>
        </h3>

        <p>
          <span style={{ display: 'block' }}>{task}</span>
        </p>

        <p>
          {tech ? (
            <span style={{ background: color, color: '#fff' }}>Tech:</span>
          ) : (
            ''
          )}
          <span style={{ display: '' }}> {tech}</span>
        </p>

        <p>
          <CaseLink
            color={color}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {location.pathname.includes('/en')
              ? '→ Go to project'
              : '→ Gå till projekt'}
          </CaseLink>
        </p>

        <p>
          {text ? (location.pathname.includes('/en') ? 'About:' : 'Om:') : ''}
          {text ? text : ''}
        </p>
      </div>
      <img alt="" src={imgSrc} />
    </CaseWrapper>
  );
};

export const CaseWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 50px; */
  font-family: var(--font-main);
  line-height: 1.4;

  h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  p {
    margin: 10px 0;
    font-family: var(--font-secondary);
  }

  div {
    width: 45%;
  }

  img {
    width: 50%;
    height: 350px;
    /* max-height: 260px; */
    padding-bottom: 6%;
    object-fit: cover;
  }

  .case-title {
    display: block;
    font-weight: 400;
  }

  .case-text-wrapper {
    padding-bottom: 6%;
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;

    img {
      align-self: flex-start;
      width: 90%;
      height: auto;
      padding-bottom: 20px;
    }

    div {
      width: 100%;
    }

    .case-title {
      display: inline;
    }

    .case-text-wrapper {
      padding-bottom: 8%;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin: 5px 0;
    }
  }

  @media (max-width: 600px) {
    img {
      width: 100%;
    }

    .case-text-wrapper {
      padding-bottom: 10%;
    }
  }
`;

export const CaseLink = styled.a`
  &:hover {
    color: ${(props) => props.color};
  }
`;
