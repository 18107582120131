import React from 'react';
import styled from 'styled-components/macro';

export const Quote = ({ sectionColor, quote, name, margins }) => {
  return (
    <QuoteWrapper sectionColor={sectionColor} margins={margins}>
      <svg
        width="152"
        height="15"
        viewBox="0 0 152 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 7.8L2.77918 7.02082C9.41809 0.381905 20.1819 0.381904 26.8208 7.02082L27.1792 7.37919C33.8181 14.0181 44.5819 14.0181 51.2208 7.37918L51.5792 7.02081C58.2181 0.381896 68.9819 0.381904 75.6208 7.02082L75.9792 7.37919C82.6181 14.0181 93.3819 14.0181 100.021 7.37918L100.379 7.02081C107.018 0.381896 117.782 0.381904 124.421 7.02082L124.779 7.37919C131.418 14.0181 142.182 14.0181 148.821 7.37918L150.4 5.8"
          stroke={sectionColor}
          strokeWidth="4"
        />
      </svg>

      {quote}
      {name ? <p>{name}</p> : ''}
    </QuoteWrapper>
  );
};

const QuoteWrapper = styled.div`
  width: 80%;
  margin: ${(props) => props.margins};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  border: 3px solid ${(props) => props.sectionColor};
  font-family: var(--font-secondary);
  color: ${(props) => props.sectionColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;

  p {
    margin-top: 20px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: var(--font-primary);
  }

  svg {
    width: 100px;
    text-align: center;
    margin: 0 auto 30px;
  }

  @media (max-width: 1024px) {
    width: 80%;
    margin: 50px 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 30px 0 50px;
  }
`;
