import React from 'react';
import styled from 'styled-components/macro';
import { FWSection, Intro } from '../styles/mainStyles';
import { Project } from '../components/Project';

export const DevSchoolWork = ({ mainColor, secondaryColor }) => {
  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      <ContentWrapper>
        <h1>Bootcamp-projekt</h1>
        <Intro mainColor={mainColor}>
          Projekt från det frontend bootcamp jag gick under hösten 2020-våren
          2021. Projekten är små, med olika fokus. Under utbildningen skapade vi
          21 projekt totalt, detta är ett urval därifrån.
        </Intro>

        <Project
          color={mainColor}
          imgSrc="../assets/labyrinth.jpg"
          name="API-/Redux-labyrinth"
          tech="Styled Components, JavaScript/ES6, React, Redux, Local Storage"
          url="https://seaton.netlify.app"
          task="Ett spel där du rör dig i en labyrint, där varje steg är en API-request som genererar nya navigeringsknappar."
        />
        <Project
          color={mainColor}
          name="Todo-app"
          task="Klassisk att-göra-lista, där dina listpunkter sparas lokalt. Uppgifter kan läggas till, tas bort, markeras som prioriterade, markeras som färdig och sorteras på kategori."
          imgSrc="../assets/todo.jpg"
          tech="Styled Components, JavaScript/ES6, React, Redux, Local Storage"
          url="https://todos-by-karin.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Survey"
          task="En undersökningssida där användaren får svara på frågor i olika utföranden: text, checkboxar, radiobuttons eller en dropdown meny. Alla resultat lagras i en Realtime Database som hostas av Google Firebase."
          imgSrc="../assets/survey.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, React, Firebase Realtime Database"
          url="http://iwannabecomeafrontenddev.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Movies"
          task="En sida som listar filmer från The Movie Database API, sorterade på olika kategorier och där varje film har en egen detaljsida."
          imgSrc="../assets/movies.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, React, React Router"
          url="https://movies-by-karin.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Happy Thoughts"
          task="Liten app där du kan skicka ett meddelande till ett API, gilla meddelanden samt läsa nya som dyker upp."
          imgSrc="../assets/happy.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, React"
          url="http://happihappiness.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Väder-app"
          task="Klassisk väder-app byggd i vanilla kod som HTML/CSS/JS. Låter användaren söka efter sin ort, använda geolocation och ta del av väderdata."
          imgSrc="../assets/weather.jpg"
          tech="HTML5, CSS3, JavaScript/ES6, API, Geolocation"
          url="https://weatherbug.netlify.app/"
        />
        <Project
          color={mainColor}
          name="Smoothiemixer"
          task="En smoothie-generator där recept skapas av ingredienser som slumpas fram. Användaren kan välja en favoritfrukt, som då dyker upp oftare i recepten."
          imgSrc="../assets/smoothie.jpg"
          tech="HTML5, CSS3, JavaScript/ES6"
          url="https://smoothday.netlify.app/"
        />
      </ContentWrapper>
    </FWSection>
  );
};

const ContentWrapper = styled.div`
  width: 76%;

  h1 {
    color: var(--color-brightRed);
    font-size: 56px;
  }

  @media (max-width: 700px) {
    width: 100%;

    h1 {
      font-size: 42px;
    }
  }
`;
