import React from 'react';

import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
  ContentWrapper,
} from '../styles/mainStyles';
import { Quote } from '../components/Quote';
import { Case } from '../components/Case';
import { Footer } from '../components/Footer';

export const Konsult = ({ mainColor, secondaryColor }) => {
  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      {/* <HeroBlock>Jag hjälper till från början till slut, från idé till produktion och lansering. Jag kopplar domän, lägger upp epost-adresser och ser till att allting funkar.</HeroBlock> */}
      <ContentWrapper>
        <h1 style={{ color: mainColor }}>Konsult</h1>
        <Intro mainColor={mainColor}>
          Som grafisk designer och frontendutvecklare kan jag erbjuda en
          helhetstjänst, där jag hjälper er med såväl webbutveckling som design
          i uppdrag utformade på det sätt som passar bäst för er.
        </Intro>
        <InfoBoxesWrapper>
          <WhiteBox mainColor={mainColor}>
            <h2>Detta kan jag hjälpa er med</h2>
            <ul>
              <li>Webbutveckling</li>
              <li>Utveckling i Hubspot & HubDB</li>
              <li>Grafisk design/Art direction</li>
              <li>Contentproduktion</li>
              <li>Korta, snabba projekt</li>
              <li>Löpande - på timme/standby</li>
              <li>Mångfascetterade projekt</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <h2>Kompetens</h2>
            <p style={{ lineHeight: '1.6' }}>
              Min kompetens innefattar ett brett spektra av design och kreativ
              utveckling. Jag drivs av mina idéer och kreativa höjd, och tränar
              mina tekniska muskler i takt med att jag producerar.
              {/* Jag är
              samtidigt ödmjuk inför min egen nivå, och nyttjar den genom att
              justera min prisbild. */}
            </p>

            <h2 style={{ marginTop: '30px' }}>Företag jag arbetat med</h2>
            <p style={{ lineHeight: '1.6' }}>
              Structsales, QueensLab, Technigo, Manpower, &frankly, Åkerströms,
              Zooma, PE Accounting, Goava, QMatic, Bufab m.fl.
            </p>
          </div>
        </InfoBoxesWrapper>

        <Quote
          margins="100px 50px"
          sectionColor={mainColor}
          quote='"Vi arbetade tillsammans med Karin när vi skulle ta fram vår nya site. Det jag uppskattar med Karin är att hon är snabb, tillmötesgående, proffsig och hjärtlig. Jag tar gärna hjälp av Karin igen!"'
          name="CAMILLA DRANGEL, MARKNADSSPECIALIST PE ACCOUNTING"
        />

        <Case
          color={mainColor}
          name="Frontend Bootcamp + UX Bootcamp"
          client="Technigo"
          task="Code coach, designer"
          imgSrc="./assets/technigo-2.jpg"
          text="Utbildar och coachar studenterna i kod och design. Utformar och designar studiematerial."
          tech="HTML/CSS/Javascript, Node.js, MongoDB, Figma"
        />
        <Case
          color={mainColor}
          name="CMS-utveckling"
          client="Structsales / div. kunder"
          task="Utvecklare & designer"
          imgSrc="./assets/structsales-motion-display-template.jpg"
          text="Produktion av webbsidor/landningssidor samt mallar för dessa + epost-mallar i CMS-miljö. Design-arbete."
          tech="Hubspot/HubL/HubDB, HTML/CSS/Javascript"
        />
        {/* <Case
          color={mainColor}
          name="&frankly - Kunskapsbank"
          client="&frankly/Structsales"
          task="Kunskapsbank i Hubspot"
          imgSrc="./assets/frankly.jpg"
          tech="Hubspot/HubL/HubDB, Javascript"
        /> */}
        <Case
          color={mainColor}
          name="Qmatic"
          client="Qmatic/Zooma"
          task="Komplett webb i Hubspot"
          imgSrc="./assets/qmatic.jpg"
          tech="Hubspot/HubL/HubDB, Javascript, SCSS, Vue"
        />
        <Case
          color={mainColor}
          name="GoHybrid"
          client="GoHybrid/QueensLab"
          task="Komplett webb i Hubspot"
          imgSrc="./assets/gohybrid.jpg"
          tech="Hubspot/HubL/HubDB, Javascript"
        />
        <Case
          color={mainColor}
          name="PE Accounting"
          client="PE Accounting/QueensLab"
          task="Komplett webb i Hubspot"
          imgSrc="./assets/peaccounting.jpg"
          tech="Hubspot/HubL/HubDB, Javascript"
        />
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};
