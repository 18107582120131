import React from 'react';
import styled from 'styled-components/macro';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      Copyright © Karin Nordkvist - Cookie D+D AB - {currentYear}
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  width: calc(100% - 256px);
  text-align: center;
  height: 20px;
  font-size: 12px;

  @media (max-width: 700px) {
    width: auto;
    text-align: left;
  }
`;
