import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useLocation, Link } from 'react-router-dom';

export const Navigation = () => {
  const navImg = useRef(undefined);
  const devLink = useRef();
  const designLink = useRef();
  const consultLink = useRef();
  const navText = useRef(undefined);
  const navContactInfo = useRef(undefined);
  const navContactMail = useRef(undefined);
  const navigate = useNavigate();
  const [mainColor, setMainColor] = useState();
  const [devActive, setDevActive] = useState(false);
  const [designActive, setDesignActive] = useState(false);
  const [consultActive, setConsultActive] = useState(false);
  const [summerActive, setSummerActive] = useState(false);
  const [mobileMenuShowing, setMobileMenuShowing] = useState(false);
  const location = useLocation();

  const setColor = () => {
    switch (location.pathname) {
      case '/':
        return '#e4ff24';
      case '/en':
        return '#e4ff24';
      case '/utveckling':
        return 'var(--color-brightRed)';
      case '/en/development':
        return 'var(--color-brightRed)';
      case '/utveckling/bootcamp':
        return 'var(--color-brightRed)';
      case '/en/development/bootcamp':
        return 'var(--color-brightRed)';
      case '/design':
        return 'var(--color-lavendarLilac)';
      case '/en/design':
        return 'var(--color-lavendarLilac)';
      case '/konsult':
        return 'var(--color-vividBlue)';
      case '/en/consulting':
        return 'var(--color-vividBlue)';
      case '/sommmar24':
        return 'var(--color-summerBlue)';
      default:
        return undefined;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        navImg.current.style.maxWidth = '90px';
        navText.current.style.opacity = '0';
      } else {
        navImg.current.style.maxWidth = '120px';
        navText.current.style.opacity = '1';
      }
    });
    setMainColor(setColor());
  }, [location.pathname]);

  const setNames = () => {
    if (location.pathname.includes('en')) {
      devLink.current.opacity = 0;
      devLink.current.innerText = 'Development';
      devLink.current.opacity = 1;
      consultLink.current.opacity = 0;
      consultLink.current.innerText = 'Consulting';
      consultLink.current.opacity = 1;
      navContactInfo.current.innerText =
        'Contact me for more information about current work or possible collaborations.';
      navContactMail.current.innerText = '→ Send me an email';
    } else {
      devLink.current.innerText = 'Utveckling';
      consultLink.current.innerText = 'Konsult';
      navContactInfo.current.innerText =
        'Kontakta mig gärna för mer information om beläggning och eventuella uppdrag.';
      navContactMail.current.innerText = '→ Mejla mig';
    }
  };

  useEffect(() => {
    setNames();
  }, [location, setNames]);

  const setActive = () => {
    switch (location.pathname) {
      case '/utveckling':
        return setDevActive(true);
      case '/en/development':
        return setDevActive(true);
      case '/utveckling/bootcamp':
        return setDevActive(true);
      case '/design':
        return setDesignActive(true);
      case '/en/design':
        return setDesignActive(true);
      case '/konsult':
        return setConsultActive(true);
      case '/en/consulting':
        return setConsultActive(true);
      case '/sommar24':
        return setSummerActive(true);
      default:
        return undefined;
    }
  };

  useEffect(() => {
    setActive();
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/en') {
      navImg.current.style.opacity = 0;
    } else {
      navImg.current.style.opacity = 1;
    }
  }, [location.pathname]);

  const moveToPageHandler = (location, setCurrentToTrue) => {
    navigate(location);
    setDevActive(false);
    setDesignActive(false);
    setConsultActive(false);
    setSummerActive(false);
    setCurrentToTrue(true);
    setMobileMenuShowing(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const MainButton = styled.button`
    font-family: inherit;
    display: block;
    border: none;
    background: none;
    cursor: pointer;
    color: ${(props) => handleColor(props)};
    font-size: 32px;
    padding: 15px 0;
    text-align: left;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${(props) => props.mainColor};
    }
  `;

  const navHandler = (event, location) => {
    setActive();
    setDevActive(false);
    setDesignActive(false);
    setConsultActive(false);
    setSummerActive(false);
    event.preventDefault();
    navigate(location);

    devLink.current.innerText = 'Development';
    consultLink.current.innerText = 'Consulting';
  };

  return (
    <NavigationWrapper>
      <Menu onClick={() => setMobileMenuShowing(!mobileMenuShowing)}>
        <img src="./assets/waves-nav-7.svg" alt="Navigation Icon" />
      </Menu>

      <div>
        <Link
          to={location.pathname.includes('/en') ? '/en' : '/'}
          onClick={moveToPageHandler}
        >
          <img
            ref={navImg}
            className="nav-logo"
            src="./assets/cookie-logo-black.svg"
            alt="Karin Nordkvist - Dev & Design"
          />
        </Link>
        <LogoSubTextWrapper ref={navText} />
      </div>
      {/* <div className="nav-contact">kontakt@karinnordkvist.se</div> */}
      <MainButtons showing={mobileMenuShowing}>
        {/* <MainButton
          mainColor={mainColor}
          onClick={() =>
            moveToPageHandler(
              location.pathname.includes('/en') ? '/en/summer24' : '/sommar24',
              setSummerActive
            )
          }
          isActive={summerActive}
          sectionColor="var(--color-summerOrange)"
          ref={devLink}
        >
          Sommar 2024 &#9788;
        </MainButton> */}
        <MainButton
          mainColor={mainColor}
          onClick={() =>
            moveToPageHandler(
              location.pathname.includes('/en')
                ? '/en/development'
                : '/utveckling',
              setDevActive
            )
          }
          isActive={devActive}
          sectionColor="var(--color-brightRed)"
          ref={devLink}
        >
          Utveckling
        </MainButton>
        <MainButton
          mainColor={mainColor}
          onClick={() =>
            moveToPageHandler(
              location.pathname.includes('/en') ? '/en/design' : '/design',
              setDesignActive
            )
          }
          isActive={designActive}
          sectionColor="var(--color-lavendarLilac)"
          ref={designLink}
        >
          Design
        </MainButton>
        <MainButton
          mainColor={mainColor}
          onClick={() =>
            moveToPageHandler(
              location.pathname.includes('/en') ? '/en/consulting' : '/konsult',
              setConsultActive
            )
          }
          isActive={consultActive}
          sectionColor="var(--color-vividBlue)"
          ref={consultLink}
        >
          Konsult
        </MainButton>
        <img alt="Wave Icon" src="./assets/wave-smaller.svg" />
        <div>
          <p ref={navContactInfo}>
            Kontakta mig gärna för mer information om beläggning och eventuella
            uppdrag.
          </p>
          <ContactLink
            color={mainColor}
            className="contact-link"
            href="mailto:karin@cookieworks.se"
            ref={navContactMail}
          >
            → Mejla mig
          </ContactLink>
          <div>
            <a
              href="https://www.linkedin.com/in/karin-nordkvist/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="icon"
                className="social-media-icon"
                src="./assets/linkedin-gray.svg"
              />
            </a>
            <a
              href="https://github.com/karinnordkvist"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="icon"
                className="social-media-icon"
                src="./assets/github-gray.svg"
              />
            </a>
            <a
              href="/"
              className="lang-link"
              onClick={(event) => navHandler(event, '/')}
            >
              SE
            </a>{' '}
            /{' '}
            <a
              href="/"
              onClick={(event) => navHandler(event, '/en')}
              className="lang-link"
            >
              EN
            </a>
          </div>
        </div>
      </MainButtons>
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.div`
  width: 100vw;
  height: 60px;
  position: fixed;
  padding: 40px;
  z-index: 10;
  display: flex;
  justify-content: space-between;

  .one-woman-show {
    position: fixed;
    top: 66vh;
    left: 36vh;
    max-width: 500px;
    line-height: 1.4;
    font-size: 24px;
    font-family: var(--font-secondary);
    /* color: #e4ff24; */
    color: #000;
    text-align: right;
  }

  img.nav-logo {
    max-width: 120px;
    margin-bottom: 20px;
    transition: all 0.5s ease;
    opacity: 1;
  }

  .nav-contact {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media (max-width: 700px) {
    padding: 30px;

    .nav-contact {
      display: none;
    }
  }
`;

const ContactLink = styled.a`
  margin-top: 5px;
  display: inline-block;
  font-family: var(--font-secondary);
  font-weight: 700;

  &:hover {
    color: ${(props) => props.color};
  }
`;

const LogoSubTextWrapper = styled.div`
  transition: opacity 0.5s ease;
`;

const MainButtons = styled.div`
  position: fixed;
  right: 10%;
  top: 15vh;
  max-width: 180px;
  transition: all 0.3s ease-in-out;

  img {
    margin-top: 40px;
  }

  div {
    margin: 50px 0 0;
  }

  .social-media-icon {
    display: inline-block;
    width: 20px;
    margin: 0 20px 0 0;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 1024px) {
    right: 3%;
  }

  @media (max-width: 700px) {
    /* display: none; */
    transform: ${(props) =>
      props.showing ? 'translateX(0)' : 'translateX(100%)'};
    opacity: ${(props) => (props.showing ? 1 : 0)};
    background: #fff;
    padding: 40px;
    max-width: 300px;
    top: 48px;
    right: -10px;
  }
`;

const handleColor = (button) => {
  switch (button.isActive) {
    case true:
      return button.sectionColor;
    case false:
      return 'var(--color-black)';
    default:
      return 'var(--color-black)';
  }
};

const Menu = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 30000000000;

    img {
      width: 50px;
    }
  }
`;
