import React from 'react';
import styled from 'styled-components/macro';
import { FWSection } from '../styles/mainStyles';

import { Footer } from '../components/Footer';

export const StartEN = ({ mainColor, secondaryColor }) => {
  return (
    <StartFWSection style={{ backgroundColor: secondaryColor }}>
      <ContentWrapper>
        <img alt="Cookie Works logo" src="./assets/cookie-logo-black.svg" />
        <p>Girl with many talents. Rootless and headless, with three legs.</p>
      </ContentWrapper>
      <Footer />
    </StartFWSection>
  );
};

const StartFWSection = styled(FWSection)`
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 76%;
  height: calc(100vh - 250px - 48px);
  position: relative;

  h1 {
    font-size: 156px;
    font-family: var(--font-secondary);
    display: block;
  }

  img {
    width: 500px;
    position: absolute;
    right: 100px;
  }

  p {
    font-family: var(--font-secondary);
    max-width: 30%;
    position: absolute;
    right: 100px;
    text-align: right;
    top: 300px;
    line-height: 1.4;
  }

  .one-woman-show {
    margin: 0 0 0 50px;
    font-size: 300px;
    font-family: var(--font-secondary);
    display: block;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: calc(100vh - 180px - 24px);
    padding-top: 40px;

    h1 {
      font-size: 42px;
    }

    img {
      width: 60%;
      max-width: 600px;
      left: 40px;
    }

    p {
      max-width: 60%;
      top: 340px;
      left: 40px;
      font-size: 24px;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
    height: calc(100vh - 190px - 30px);
    padding-top: 40px;

    h1 {
      font-size: 42px;
    }

    img {
      width: 80%;
      /* max-width: 280px; */
      right: 40px;
      top: -40px;
    }

    p {
      max-width: 80%;
      top: 280px;
      right: 40px;
      font-size: 24px;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    height: calc(100vh - 190px - 30px);
    padding-top: 40px;

    h1 {
      font-size: 42px;
    }

    img {
      width: 80%;
      right: 20px;
    }

    p {
      max-width: 100%;
      top: 200px;
      right: 20px;
      font-size: 18px;
    }
  }
`;
