import React from 'react';
import styled from 'styled-components/macro';
import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
} from '../styles/mainStyles';
import { Link } from 'react-router-dom';

import { Quote } from '../components/Quote';
import { Case } from '../components/Case';
import { CaseWrapper } from '../components/Project';
import { Footer } from '../components/Footer';

export const Summer = ({ mainColor, secondaryColor }) => {
  const moveToPageHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      {/* <HeroBlock>Jag hjälper till från början till slut, från idé till produktion och lansering. Jag kopplar domän, lägger upp epost-adresser och ser till att allting funkar.</HeroBlock> */}
      <ContentWrapper>
        <h1>Sommar 2024 &#9788;</h1>
        <Intro mainColor="#fffffa">
          Passa på! Från och med 25 juni till 31 augusti kör jag sommarpriser på
          mina tjänster och har tagit fram två maxade sommarpaket speciellt för
          småföretagare som vill komma igång och köra så det ryker efter
          semestrarna.
        </Intro>

        <h2 style={{ marginBottom: '30px' }}>Webb-paketet</h2>
        <InfoBoxesWrapper>
          <WhiteBox mainColor="var(--color-summerOrange)">
            <h3 style={{ color: '#000' }}>I din webbsida ingår</h3>
            <ul style={{ color: '#000' }}>
              <li>Komma igång-support (domän/webbhotell)</li>
              <li>Fullt redigerbart innehåll</li>
              <li>Responsiv design</li>
              <li>Genomgång av publiceringsverktyg</li>
              <li>Teknisk support</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <p style={{ lineHeight: '1.6' }}>
              Oavsett om det handlar om en enkel sida med kontaktinformation
              eller om det är flera undersidor med specifika detaljer så hjälper
              jag er att skapa er plats på nätet.
            </p>
            <h3 style={{ marginTop: '30px' }}>Verktyg</h3>
            <p style={{ lineHeight: '1.6' }}>
              Din hemsida byggs med/hostas av Elementor och drivs med hjälp av
              Wordpress. Vad det innebär är en sajt som uppdateras automatiskt,
              stödjer tillägg och verktyg som kan behövas både nu och senare, en
              enklare och smidigare användarupplevelse för dig samt en stadig
              grund för din sajt.
            </p>
            <h3 style={{ marginTop: '30px' }}>Design</h3>
            <p style={{ lineHeight: '1.6' }}>
              Beroende på om du själv bistår med logotyp och design erbjuder jag
              en rabatterad grundläggande design. Då ingår ett set med färger
              och typsnitt, samt design av knappar, navigation och grundläggande
              bildspråk.
            </p>
            <h3 style={{ marginTop: '30px' }}>Pris</h3>
            <p style={{ lineHeight: '1.6' }}>Från 10 000 SEK.</p>
          </div>
        </InfoBoxesWrapper>

        <h2 style={{ marginBottom: '30px' }}>Design-paketet</h2>
        <InfoBoxesWrapper>
          <div className="top-text" style={{ padding: '20px 80px 0 0' }}>
            <p style={{ lineHeight: '1.6' }}>
              En grafisk profil är ett finare uttryck för ditt företags ansikte
              utåt. Det är genom din profil som dina kunder kommer ihåg dig,
              genom färger och typografi som man kan skapa en uppfattning om vad
              ditt företag erbjuder och hur du/ni "känns". Ett företag som
              investerat i ett genomarbetat utseende signalerar seriositet och
              professionalism, och kan vara det som gör att en kund väljer just
              dig.
            </p>

            <p style={{ lineHeight: '1.6', marginTop: '30px' }}>
              När jag tar fram er grafiska profil tar jag hänsyn till ert
              företags bakgrund, vision och, såklart, era önskemål. I leveransen
              ingår självklart en guide och vägledning i hur ni använder er nya
              profil.
            </p>

            <h3 style={{ marginTop: '30px' }}>Pris</h3>
            <p style={{ lineHeight: '1.6' }}>Från 5 000 SEK.</p>
          </div>
          <WhiteBox mainColor="var(--color-summerOrange)">
            <h3 style={{ color: '#000' }}>I din profil ingår</h3>
            <ul style={{ color: '#000', paddingRight: '20px' }}>
              <li>Färgschema</li>
              <li>Typografi - typsnitt</li>
              <li>Guide till din profil</li>
            </ul>

            <h3 style={{ color: '#000', marginTop: '20px' }}>Tillval</h3>
            <ul style={{ color: '#000', paddingRight: '20px' }}>
              <li>Logotyp</li>
              <li>Bildspråk</li>
              <li>Mallar till digitala verktyg</li>
            </ul>
          </WhiteBox>
        </InfoBoxesWrapper>

        <Quote
          margins="20px 50px 20px 0"
          sectionColor={mainColor}
          quote="Jag vill göra världen till en vackrare plats. Den digitala världen, alltså."
        />
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};

const ContentWrapper = styled.div`
  width: 76%;
  color: var(--color-white);

  h1 {
    color: var(--color-summerOrange);
    font-size: 56px;
  }

  h2 {
    color: var(--color-summerOrange);
    font-size: 36px;
  }

  h3 {
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px 0;
    letter-spacing: 1.6px;
  }

  @media (max-width: 700px) {
    width: 100%;

    h1 {
      font-size: 42px;
    }
  }
`;

const BootcampLink = styled(Link)`
  &:hover {
    color: var(--color-summmerOrange);
  }
`;
