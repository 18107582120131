import React from 'react';
import styled from 'styled-components/macro';
import {
  WhiteBox,
  FWSection,
  InfoBoxesWrapper,
  Intro,
} from '../styles/mainStyles';
import { Link } from 'react-router-dom';

import { Quote } from '../components/Quote';
import { Case } from '../components/Case';
import { CaseWrapper } from '../components/Project';
import { Footer } from '../components/Footer';

export const DevEN = ({ mainColor, secondaryColor }) => {
  const moveToPageHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <FWSection style={{ backgroundColor: secondaryColor }}>
      {/* <HeroBlock>Jag hjälper till från början till slut, från idé till produktion och lansering. Jag kopplar domän, lägger upp epost-adresser och ser till att allting funkar.</HeroBlock> */}
      <ContentWrapper>
        <h1>Web Development</h1>
        <Intro mainColor={mainColor}>
          I will make sure your web project turns out the way you like, when you
          want it and support you fully. I can connect your domains and the
          heavy tech parts, add email settings and make sure the deploy goes as
          planned.
        </Intro>
        <InfoBoxesWrapper>
          <WhiteBox mainColor="var(--color-brightRed)">
            <h2>Project features</h2>
            <ul>
              <li>Fully editable content</li>
              <li>Custom made sections</li>
              <li>Responsive design</li>
              <li>SEO</li>
              <li>Demonstration of Publishing Tool</li>
            </ul>
          </WhiteBox>

          <div className="top-text">
            <h2>Tech</h2>
            <p style={{ lineHeight: '1.6' }}>
              I work with the MERN-/Jamstack, focusing mainly on React in
              combination with the headless CMS Sanity hosted by Netlify. I
              write with clean code, honor effective performance and create
              custom functionality for your product.
            </p>
            <h2 style={{ marginTop: '30px' }}>Competence & philosophy</h2>
            <p style={{ lineHeight: '1.6' }}>
              As a fontend dev with design background I can guarantee a pixel
              perfect product, developed with performance, UX and overall design
              in mind. If there is something I don't know how to do, I will look
              it up and add it to my work flow. I'm a professional Googler and
              as of today I have not come across anything I couldn't solve with
              the help of Google.
            </p>
          </div>
        </InfoBoxesWrapper>

        <Case
          color={mainColor}
          name="Skyresponse"
          client="Skyresponse / Smarkit"
          task="Web"
          imgSrc="./assets/skyresponse.png"
          tech="Hubspot CMS – HTML/CSS/JS/HUBL"
          url="https://skyresponse.com/"
        />
        <Case
          color={mainColor}
          name="Seatons Strand"
          client="Housing Cooperative Seatons Strand"
          task="Logotype, identity, web"
          imgSrc="./assets/seatons-strand.jpg"
          tech="Frontend: React + Styled Components / Backend: Sanity"
          url="https://seatonsstrand.se/"
        />
        <Case
          color={mainColor}
          name="Caroline Borg"
          client="Caroline Borg - stylist/fotograf/storyteller"
          task="Logotype, identity, web"
          imgSrc="./assets/caroline-borg.png"
          tech="Frontend: React + Styled Components / Backend: Sanity"
          url="https://carolineborg.se"
        />

        <CaseWrapper>
          <div className="case-text-wrapper">
            <h3>
              <span className="case-title">
                Technigo Frontend Bootcamp projects
              </span>
            </h3>

            <p>
              <span style={{ display: 'block' }}>
                A selection of some of the 21 projects I built during the 24
                weeks of bootcamp.
              </span>
            </p>

            <p>
              <BootcampLink
                to="/en/development/bootcamp"
                onClick={moveToPageHandler}
              >
                → See projects
              </BootcampLink>
            </p>
          </div>
          <img alt="" src="./assets/bootcamp.jpg" />
        </CaseWrapper>

        <CaseWrapper>
          <div className="case-text-wrapper">
            <p>
              <span style={{ display: 'block' }}>
                Want to see more cases? Most of my dev work can be seen under
                Consulting, as I've been working mostly as a consultant since
                becoming a web developer 👌
              </span>
            </p>

            <p>
              <BootcampLink to="/en/consulting" onClick={moveToPageHandler}>
                → Go to Consulting
              </BootcampLink>
            </p>
          </div>
        </CaseWrapper>

        <Quote
          margins="20px 50px"
          sectionColor={mainColor}
          quote='"As a fontend dev with design background I can guarantee a pixel
              perfect product, developed with performance, UX and overall design
              in mind."'
        />
      </ContentWrapper>
      <Footer />
    </FWSection>
  );
};

const ContentWrapper = styled.div`
  width: 76%;

  h1 {
    color: var(--color-brightRed);
    font-size: 56px;
  }

  h2 {
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px 0;
    letter-spacing: 1.6px;
  }

  @media (max-width: 700px) {
    width: 100%;

    h1 {
      font-size: 42px;
    }
  }
`;

const BootcampLink = styled(Link)`
  &:hover {
    color: var(--color-brightRed);
  }
`;
